
<template>
  <div>
    <page-header title='Resumo'></page-header>

    <v-row class="px-3">
      <v-col>
        <course-calendar :use_with_menu="true"></course-calendar>
      </v-col>

      <v-col >
        <next-courses></next-courses>
        <!-- <invoicing class="mt-5"></invoicing> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import CourseCalendar from '@/components/courses/agenda/CourseCalendar.vue'
import NextCourses from '@/components/courses/agenda/NextCourses.vue'
// import TotalEnrolled from '../student_counters/TotalEnrolled.vue'
// import TotalRegistered from '../student_counters/TotalRegistered.vue'
// import TotalPending from '../student_counters/TotalPending.vue'
import CounterInfo from '../counters/CounterInfo.vue'
// import Received from './financial/counters/finance_info/finance_cards/Received.vue'
// import Expenditure from './financial/counters/finance_info/finance_cards/Expenditure.vue'
// import Invoicing from './financial/counters/invoicing/Invoicing.vue'
// import PageHeader from './shared/PageHeader.vue'

export default {
  name: 'CoordinatorHome',
  components: {
    CourseCalendar,
    NextCourses,
    // TotalEnrolled,
    // TotalRegistered,
    // TotalPending,
    CounterInfo,
    // PageHeader,
    // EventsCalendar,
    // Received,
    // Expenditure,
    // Invoicing,
    // NextEvents,
  },
  data() {
    return {
      loading: false,
      key: null,
      // enrolled: {
      //   value: 20000,
      //   title: 'Homologados',
      //   icon: 'mdi-account-check-outline',
      //   title_class: 'primary--text overline',
      //   icon_color: 'primary',
      // },
      // registered: {
      //   value: 20000,
      //   title: 'Inscritos',
      //   icon: 'mdi-account-plus-outline',
      //   title_class: 'primary--text overline',
      //   icon_color: 'primary',
      // },
      // pending: {
      //   value: 20000,
      //   title: 'Selecionados',
      //   icon: 'mdi-account-clock-outline',
      //   title_class: 'primary--text overline',
      //   icon_color: 'primary',
      // },
    }
  },
  methods: {
    getData(){
      this.loading = true
      setTimeout(() => this.loading = false, 1200)
    },
    
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    name() {
      return null 
    },
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  mounted () {
    this.getData()
  },
  watch: {
    data(nv,ov) {
      if (nv){
      } else if (nv == false){
      }
    }
  },
}
</script>

<style scoped>

</style>