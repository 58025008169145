import { render, staticRenderFns } from "./CoordinatorHome.vue?vue&type=template&id=c2b53924&scoped=true&"
import script from "./CoordinatorHome.vue?vue&type=script&lang=js&"
export * from "./CoordinatorHome.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2b53924",
  null
  
)

export default component.exports